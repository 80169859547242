import { DatePicker, Dropdown, Form, Input, InputNumber, message, Radio, Select, Space } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import TextArea from 'antd/lib/input/TextArea';
import { Api } from 'client/lib/api';
import { CreateCobranca as ICreateCobranca } from 'client/lib/cobranca/models';
import { useModalContext } from 'context/ModalContext';
import moment from 'moment';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { IParcela } from '../models';
import Table, { ColumnType } from 'antd/lib/table';
import { RenderBoletoActionButtons } from 'components/helpers/RenderBoletoActionButtons';
import { PagamentoMenu } from 'components/AcordoDetails/components/PagamentoMenu';


export type HistoricoPagamentoHandler = {
  //submitForm: () => void;
};

export type HistoricoPagamentoProps = {
  data: any;
  getData: (params: any) => void;
  getDataParams: any;
};

const HistoricoPagamento: React.ForwardRefRenderFunction<HistoricoPagamentoHandler, HistoricoPagamentoProps> = (
  { data , getData, getDataParams  },
  ref
) => {
  //const [form] = Form.useForm();
  //const [paymentType, setPaymentType] = useState('');
  const dateFormat = 'DD/MM/YYYY';
  const [listPagamentos, setListPagamentos] = useState([]);
  const { hideModal, dataModal, onLoading, abortLoading } = useModalContext();
  type ColumnType = {
    title: string;
    dataIndex: string;
    render?: (_: any, record: any) => React.ReactNode;
  };
  async function forcarProcessamento(idPagamento: string) {
    Api()
      .pagamento.forcarProcessamento(idPagamento)
      .then((response) => {
        Api()
          .parcela.listPayments(data.id)
          .then(
            (payments) => { setListPagamentos(payments.data)  }
          )
          .catch((err) => { console.error(err) }

          );
      });
  }


  const columns: ColumnType[] = [
    {
      title: '#',
      dataIndex: 'idPagamento',
      render: (_, record) => {
        return <p style={{ textAlign: 'center', margin: 0 }}>{record.id}</p>;
      }
    },
    {
      title: 'Data da última atualização com Integração',
      dataIndex: 'integracao_pagamento_last_update',
      render: (_, record) => {
        return (
          <p style={{ textAlign: 'center', margin: 0 }}>
            {record?.integracao_pagamento_last_update
              ? moment(record?.integracao_pagamento_last_update).format(dateFormat)
              : '-'}
          </p>
        );
      }
    },
    {
      title: 'Data Limite Pagamento',
      dataIndex: 'dataLimitePagamento',
      render: (_, record) => {
        return (
          <p style={{ textAlign: 'center', margin: 0 }}>
            {record?.data_limite_pagamento
              ? moment(record?.data_limite_pagamento).format(dateFormat)
              : '-'}
          </p>
        );
      }
    },
    {
      title: 'Data de Cancelamento',
      dataIndex: 'dataCancelamento',
      render: (_, record) => {
        return (
          <p style={{ textAlign: 'center', margin: 0 }}>
            {record?.data_cancelamento
              ? moment(record?.data_cancelamento).format(dateFormat)
              : '-'}
          </p>
        );
      }
    },
    {
      title: 'Data de baixa',
      dataIndex: 'dataBaixa',
      render: (_, record) => {
        return (
          <p style={{ textAlign: 'center', margin: 0 }}>
            {record?.data_baixa
              ? moment(record?.data_baixa).format(dateFormat)
              : '-'}
          </p>
        );
      }
    },
    {
      title: 'Data Pagamento',
      dataIndex: 'dataPagamento',
      render: (_, record) => {
        const data = record?.data_pagamento
          ? moment(record?.data_pagamento).format(dateFormat)
          : '-';
        return <p style={{ textAlign: 'center', margin: 0 }}>{data}</p>;
      }
    },

    {
      title: 'Valor Pago',
      dataIndex: 'valorPago',
      render: (_, record) => {
        return (
          <p style={{ textAlign: 'center', margin: 0 }}>
            {record?.valor_pago
              ? moment(record?.valor_pago).format(dateFormat)
              : '-'}
          </p>
        );
      }
    },
    {
      title: 'Método Pagamento',
      dataIndex: 'info_boleto',
      render: (_, record) => {
        return (
          <p style={{ textAlign: 'center', margin: 0 }}>
            {record?.carteira_metodo_pagamento?.metodo_pagamento?.nome || '-'}
          </p>
        );
      }
    },

    {
      title: 'Informações de pagamento',
      dataIndex: 'info_boleto',
      render: (_, record) => (
        <RenderBoletoActionButtons data={record} onForceProcess={forcarProcessamento} />
      )
    },
    {
      title: 'Integração',
      dataIndex: 'idIntegracao',
      render: (_, record) => {
        return <p style={{ textAlign: 'center', margin: 0 }}>{record?.identificador || '-'}</p>;
      }
    },
    {
      title: 'Status Pagamento',
      dataIndex: 'statusPagamento',
      render: (_, record) => {
        return <p style={{ textAlign: 'center', margin: 0 }}>{record?.status}</p>;
      }
    },
    {
      title: 'Situação pagamento',
      dataIndex: 'situacaoPagamento',
      render: (_, record) => {
        return (
          <p style={{ textAlign: 'center', margin: 0 }}>
            {record?.situacao || '-'}
          </p>
        );
      }
    },
    {
      title: '',
      dataIndex: '',
      render: (_, record) => {
        return (
          <div>
            <Space>
              <Dropdown.Button
                trigger={['click']}
                overlay={
                  <PagamentoMenu
                    dataPagamento={record}
                    getData={getData}
                    getDataParams={[getDataParams]}
                  />
                }
              />
            </Space>
          </div>
        );
      }
    }   
  ];

  useEffect(() => {
    Api()
      .parcela.listPayments(data.id)
      .then(
        (payments) => { setListPagamentos(payments.data)  }
      )
      .catch((err) => { console.error(err) }

      );
  }, []);

   return (<>
    <h3 className="mt20-print">Pagamentos: </h3>
    <Table
      scroll={{ x: true }}
      columns={columns}
      dataSource={listPagamentos}
      pagination={false}
      bordered
      size="small"
    />
    </>

   );
   
};

export default forwardRef(HistoricoPagamento);
